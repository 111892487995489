
@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-ExtraLight.ttf') format('ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Regular.ttf') format('ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Medium.ttf') format('ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-SemiBold.ttf') format('ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url('./public/fonts/Poppins-Bold.ttf') format('ttf');
    font-weight: 700;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.Irish_font{
    font-family: 'Irish Grover',sans-serif;
}
.checked {
    color: orange;
}
input[type='radio'] {
    appearance: none;
    width: 12px;
    height: 12px;
    border: 1px solid #aeaeae;
    border-radius: 0;
    outline: none;
    box-shadow: 0 0 5px rgba(153, 153, 153, 0.78);
    transition: box-shadow 0.3s ease;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 69%;
    height: 65%;
    margin: 14% auto;
    border-radius: 0;
}

 input[type='radio']:checked:before {
    background: #aeaeae;
}
/*input:-webkit-autofill:disabled,*/
/*input:-internal-autofill-selected {*/
/*    background: #060301!important;*/
/*    color: #f0eef3!important;*/
/*}*/
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border: 0;
}
body{
    min-height: 100vh;
}